import clsx from 'clsx';
import { useEffect, useState } from 'react';

import collectAllIcon from 'assets/img/common/collectAllIcon.webp';
import lockIcon from 'assets/img/common/lockWithSircle.webp';
import { ReactComponent as InfoIcon } from 'assets/img/DividendsPage/info.svg';

import { useLocalization } from 'components/Internationalization';
import { amount, formatUsdAmount, getImgSrc } from 'func/common';
import { ETokenCode } from 'components/constants/constants';
import { ButtonType } from 'components/Base/Button/types';
import { Links } from 'components/constants';
import { useAppSelector } from 'hooks/useAppSelector';
import { userToken } from 'store/user/user.selectors';

import DropDown from 'components/Base/DropDown';
import Button from 'components/Base/Button';
import DividendsInfo from 'components/Pages/DividendsPage/components/DividendsInfo';
import CurrencyList from 'components/common/CurrencyList';
import StyledLink from 'components/Base/Links/StyledLink';
import NotAuthCover from '../NotAuthCover';

import styles from './styles.module.scss';
import { IBalanceCard } from './types';

const BalanceCard: React.FC<IBalanceCard> = ({
  className = '',
  icon,
  title,
  description,
  balanceUsd,
  collectedUsd,
  balances,
  collected,
  convertedBalances,
  disabled,
  buttonTitle = '',
  rakebackForbidden = false,
  minRankRakeback,
  onCollect,
  onToken,
  onInfo,
}) => {
  const { translate, language } = useLocalization();
  const token = useAppSelector(userToken);

  const [dropDownInfo, setDropDownInfo] = useState(null);
  const [titleState, setTitleState] = useState(false);

  useEffect(() => {
    if (convertedBalances.length && language) {
      const prepareData = convertedBalances.map((elem) => {
        return {
          title: `${amount(elem?.title || '0')} ${elem.displayName}`,
          img: getImgSrc(elem.displayName),
          tokenCode: elem.src,
          func: () => onToken(elem?.src),
        };
      });
      const firstElem = {
        title: translate('dividends.balance.dropdown.top.text'),
        img: collectAllIcon,
        func: () => onToken(null),
      };
      setDropDownInfo([firstElem, ...prepareData]);
    }
  }, [convertedBalances, language]);

  const handleBalance = () => setTitleState(false);
  const handleCollected = () => setTitleState(true);

  return (
    <div className={clsx(styles.wrapper, className)}>
      {token ? null : <NotAuthCover />}
      <img className={styles.icon} src={icon} alt="icon" />
      <div className={styles.body}>
        <div className={styles.header}>
          <p>{title}</p>
          {onInfo ? (
            <button onClick={onInfo}>
              <InfoIcon className={styles.info} />
            </button>
          ) : (
            <DividendsInfo text={description} />
          )}
        </div>
        {!rakebackForbidden ? (
          <>
            <button className={styles.title} onClick={handleBalance}>
              <p>{translate('dividends.balance.title.mobile')}</p>
              <div>
                <img src={getImgSrc(ETokenCode.USD)} alt={ETokenCode.USD} />
                <h3 className={titleState ? styles.titleInactive : styles.titleActive}>
                  {formatUsdAmount(balanceUsd)}
                </h3>
              </div>
            </button>
            <button className={styles.title} onClick={handleCollected}>
              <p>{translate('dividends.balance.collected.title.mobile')}</p>
              <div>
                <img src={getImgSrc(ETokenCode.USD)} alt={ETokenCode.USD} />
                <h3 className={!titleState ? styles.titleInactive : styles.titleActive}>
                  {formatUsdAmount(collectedUsd)}
                </h3>
              </div>
            </button>
            <CurrencyList balances={!titleState ? balances : collected} />
            <div className={styles.buttonWrap}>
              {dropDownInfo ? (
                <DropDown
                  data={dropDownInfo}
                  width="100%"
                  textPadding="50px"
                  tokens
                  disabled={disabled || titleState}
                  isUp
                />
              ) : null}
            </div>
            <div className={styles.buttonWrap}>
              <Button
                variant={ButtonType.primary}
                title={buttonTitle || translate('dividends.balance.button.title')}
                width="100%"
                disabled={disabled || titleState}
                onclick={onCollect}
              />
            </div>
          </>
        ) : null}
        {rakebackForbidden ? (
          <div className={styles.forbiddenRakeback}>
            <img src={lockIcon} alt="lock" />
            <p className={styles.desc}>
              {translate('popups.rakeback.collect.description')} {minRankRakeback}.
            </p>
            <StyledLink to={Links.games} className={styles.link}>
              {translate('popups.rakeback.collect.play')}
            </StyledLink>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default BalanceCard;

import styled from 'styled-components';

import { ICashbackProgressBarStyle } from './types';

const ProgressBarStyle = styled.div.withConfig({
  shouldForwardProp: (prop) => !['progresswidth'].includes(prop),
})<ICashbackProgressBarStyle>`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 131px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 20px 35px;
  font-size: 10px;

  .image-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    position: relative;
    height: 30px;

    div {
      position: absolute;
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      margin: 0;
      img {
        width: 23px;
      }
    }
    div:nth-of-type(1) {
      left: ${(props) => (!props.rankline ? '-10px' : '0')};
      bottom: 6px;
    }
    div:nth-of-type(2) {
      left: 25%;
      color: ${(props) => (props.progresswidth >= 25 ? '#8BC834' : '#FFFFFF')};
    }
    div:nth-of-type(3) {
      left: 48%;
      color: ${(props) => (props.progresswidth >= 48 ? '#8BC834' : '#FFFFFF')};
    }
    div:nth-of-type(4) {
      left: 73%;
      color: ${(props) => (props.progresswidth >= 73 ? '#8BC834' : '#FFFFFF')};
    }
    div:nth-of-type(5) {
      right: ${(props) => (!props.rankline ? '-18px' : '15px')};
      bottom: 6px;
    }
  }

  .image-container--rank-percent {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 16px !important;
    line-height: 18px;
    color: #ffffff;
  }

  .line-container {
    width: 100%;
    height: 6px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    display: flex;
    justify-content: flex-start;
    position: relative;
  }
  .line {
    height: 6px;
    border-radius: 3px;
    width: ${(props) => String(props.progresswidth) + '%'};
    background: #8bc834;
    position: relative;
    transition: 3s linear 0s;
  }

  .description-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .description-items-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }

  .description-wrap-mob {
    display: none;
  }
  .description-percent-wrap {
    display: flex;
    padding-left: 7px;
  }
  .description-item,
  .mob-item {
    display: flex;
    align-items: center;
    padding: 5px 0 0 0;
    flex-wrap: wrap;
    justify-content: center;
    h3,
    h4,
    h5 {
      font-size: 13px;
      font-weight: 600;
      padding: 0 0 0 5px;
      margin: 0 5px 0 0;
      line-height: 18px;
      flex-shrink: 0;
    }
    p,
    h6 {
      font-size: 13px;
      color: #696c80;
      font-weight: 600;
      margin: 0;
      line-height: 18px;
      flex-shrink: 0;
    }
    h3 {
      color: #8bc834;
    }
    h4 {
      color: #ffffff;
    }
    h5 {
      color: #ffbd00;
    }
    span {
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: #696c80;
    }
  }

  .mob-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .description-item--progress {
    width: 100%;
    text-align: center;
    font-size: 18px;
    color: #ffffff;
    font-weight: 600;
    margin: 0;
    line-height: 18px;
    flex-shrink: 0;
  }

  @media (max-width: 1210px) {
  }
  @media (max-width: 1110px) {
    .description-item,
    .mob-item {
      p,
      h3,
      h4,
      h5,
      h6 {
        font-size: 11px;
      }
    }
  }
  @media (max-width: 1024px) {
    .description-item,
    .mob-item {
      p,
      h3,
      h4,
      h5,
      h6 {
        font-size: 13px;
      }
    }
  }
  @media (max-width: 900px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 640px) {
  }
  @media (max-width: 580px) {
    .image-container--rank-percent {
      font-size: 13px !important;
    }
    .description-item--progress {
      font-size: 16px;
    }
  }
  @media (max-width: 470px) {
    height: auto;
    .image-container {
      div:nth-of-type(2),
      div:nth-of-type(4) {
        display: none;
      }
    }
    .description-wrap {
      margin-top: 7px;
    }
    .description-wrap-mob {
      padding: 5px 0 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
    .description-item,
    .mob-item {
      p,
      h3,
      h4,
      h5,
      h6 {
        font-size: 11px;
      }
      p {
        color: #ffffff;
      }
    }
  }
  @media (max-width: 400px) {
  }
  @media (max-width: 370px) {
    .description-item,
    .mob-item {
      p,
      h3,
      h4,
      h5,
      h6 {
        font-size: 10px;
      }
    }
  }
`;

export default ProgressBarStyle;

import { gql } from '@apollo/client';

export const QUERY_LIST_FOR_AUTH = gql`
  query auth($tokenCode: String!) {
    wallets {
      availableBalance
      totalWager
      token {
        name
        tokenCode
        displayName
      }
    }
    profile {
      alias
      createdAt
      email
      id
      telegramId
      roles
      type
      preferences {
        promoEmail
        newsEmail
        accountActivityAlert
        language
      }
      avatar {
        avatar
        id
        weight
      }
      rank {
        bonus
        cashback
        id
        wager
        vip
      }
      referralCode
      totalWager
      totalMined
      totalBets
    }
    tokenMiningPrices {
      miningPrice
      tokenCode
      displayName
    }
    getProviders {
      name
      logo
    }
    getSettings {
      name
      value
    }
    footerLogos {
      id
      logo
      weight
    }
    wheelStyle {
      wheel_style
      wheel_background_color
      wheel_button_color
    }
    ranks {
      id
      wager
      cashback
      bonus
      tips
      avatars
      vip
      vault
      rakeback
      displayName
    }
    exchangeRates {
      token {
        tokenCode
      }
      usdValue
    }
    footer {
      id
      category
      name
      url
      external
      weight
    }
    activeTokens {
      tokenCode
      displayName
      weight
    }
    tokenWithNetworks {
      tokenCode
      displayName
      name
      networks {
        minimumDeposit
        minimumWithdrawal
        withdrawalFee
        network {
          code
        }
      }
    }
    networks {
      code
      explorer
      name
      standard
    }
    wallet(tokenCode: $tokenCode) {
      availableBalance
      totalWager
      token {
        tokenCode
        displayName
      }
    }
    missionRound {
      id
      startAt
      endAt
      prize
      prizeTokenCode
    }
    myBonuses {
      amount
      startAt
      endAt
      tokenCode
      displayName
      currentWager
      requiredWager
      status
      id
      userFreespin {
        numberOfSpins
      }
      bonus {
        name
        rules
        maxBonus
        minDeposit
        wagerMultiplier
        minBet
        maxBet
        type
        freeSpinSetting {
          gameId
          numberOfSpins
          currency
          game {
            name
            image
            slug
          }
        }
      }
    }
    userNotifications {
      id
      type
      title
      content
      isRead
      createdAt
    }
  }
`;

import { useContext, useEffect, useState } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { useQuery } from '@apollo/client';
import { pathOr } from 'ramda';

import { ReactComponent as Logo } from 'assets/img/header/animatedLogo.svg';
import bonusIcon from 'assets/img/common/giftHand.webp';

import { media } from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import {
  setActiveBonusTokens,
  setFreeSpinsSettings,
  setIsActiveUserBonus,
  setIsDisplayBonus,
  setUserBonuses,
} from 'store/data';
import { GET_BONUSES, GET_MY_BONUSES } from 'graphQl/query/bonus/indes';
import { GET_COMMON_SETTINGS } from 'graphQl/query/settings/bonusSettings';
import { Links } from 'components/constants';
import { popUps, SettingName } from 'components/constants/constants';
import { EBonus, EWheelImg, WheelStatus } from 'components/constants/games';
import { ContextPopUps } from 'context';
import { getCommonSettingsValue } from 'func/common';
import { dataUserBonuses } from 'store/data/data.selectors';
import { userToken } from 'store/user/user.selectors';
import {
  checkIsActiveMyBonus,
  getActiveBonusTokens,
  getBonusList,
  getFreeSpinsSettings,
  getMyBonusCount,
  getMyBonusList,
} from 'func/prepareDataBonus';
import { GET_WHEEL_STILE } from 'graphQl/query/wheel/wheel';

import LazyLoadImg from 'components/Base/LazyLoadImg/LazyLoadImg';
import Link from 'components/Base/Links/Link';

import styles from './styles.module.scss';
import { useLocalization } from 'components/Internationalization';

const HeaderLogo: React.FC = () => {
  const dispatch = useAppDispatch();
  const { setPopUpsOpen } = useContext(ContextPopUps);
  const { translate } = useLocalization();

  const [displayWheel, setDisplayWheel] = useState(false);
  const [logoIcon, setLogoIcon] = useState(null);
  const [wheelUrl, setWheelUrl] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [bonusCount, setBonusCount] = useState(0);

  const userBonuses = useAppSelector(dataUserBonuses);
  const token = useAppSelector(userToken);

  const { data: mainSettings } = useQuery(GET_COMMON_SETTINGS, { fetchPolicy: 'cache-only' });

  const { data: dataWheelStyle } = useQuery(GET_WHEEL_STILE, {
    fetchPolicy: 'cache-only',
  });

  const { data: dataMyBonuses } = useQuery(GET_MY_BONUSES, {
    fetchPolicy: 'cache-only',
    skip: !token,
  });

  const { data: dataBonuses } = useQuery(GET_BONUSES, {
    fetchPolicy: 'cache-only',
    skip: Boolean(token),
  });

  const handleWheel = () => {
    setPopUpsOpen({
      modalOpen: popUps.wheelFortune,
      data: { config: popUps.wheelFortune },
    });
  };

  useEffect(() => {
    if (dataBonuses) {
      const list = getBonusList(dataBonuses.bonuses);
      if (!list.length) {
        dispatch(setIsDisplayBonus(EBonus.closed));
      } else {
        dispatch(setIsDisplayBonus(EBonus.available));
      }
    }
  }, [dataBonuses]);

  useEffect(() => {
    if (dataMyBonuses) {
      const list = getMyBonusList(dataMyBonuses.myBonuses);
      const isActiveBonus = checkIsActiveMyBonus(list);
      const freeSpinsSettings = getFreeSpinsSettings(list);
      const activeBonusTokens = getActiveBonusTokens(list);

      dispatch(setIsActiveUserBonus(isActiveBonus));
      dispatch(setUserBonuses(dataMyBonuses.myBonuses));
      dispatch(setFreeSpinsSettings(freeSpinsSettings));
      dispatch(setActiveBonusTokens(activeBonusTokens));
      if (!list.length) {
        dispatch(setIsDisplayBonus(EBonus.closed));
      } else {
        dispatch(setIsDisplayBonus(EBonus.available));
      }
    }
  }, [dataMyBonuses]);

  useEffect(() => {
    if (dataWheelStyle) {
      const style = pathOr<string>('', ['wheelStyle', 'wheel_style'], dataWheelStyle);

      setWheelUrl(`${media.wheel}${style}/${EWheelImg.wheelHeader}`);
    }
  }, [dataWheelStyle]);

  useEffect(() => {
    if (mainSettings) {
      const wheelSt = getCommonSettingsValue(mainSettings.getSettings, SettingName.wheelStatus);
      const logoDB = getCommonSettingsValue(mainSettings.getSettings, SettingName.logo);

      setLogoIcon(logoDB ? `${media.logo}${logoDB}` : null);
      setDisplayWheel(wheelSt === WheelStatus.active);
    }
  }, [mainSettings]);

  useEffect(() => {
    if (token && userBonuses) {
      const bonusesList = getMyBonusCount(userBonuses);
      setBonusCount(bonusesList);
    }
  }, [userBonuses, token]);

  return (
    <div className={styles.logoWrap}>
      <div>
        <Link to={Links.home} onClick={() => scroll.scrollTo(0, { duration: 0, delay: 0 })}>
          {logoIcon ? (
            <img src={logoIcon} alt="logo" className={styles.logoImage} />
          ) : (
            <Logo className={styles.logoImage} />
          )}
        </Link>
      </div>
      <div className={styles.logoImageWrap}>
        <Link to={Links.bonus} onClick={() => scroll.scrollTo(0, { duration: 0, delay: 0 })}>
          <div className={styles.bonusWrap}>
            <img src={bonusIcon} alt="icon" />
            <p>{translate('vip.benefits.categories.bonuses')}</p>
          </div>
          {/* {bonusCount ? <h3 className={styles.logoGiftCount}>{bonusCount}</h3> : null} */}
        </Link>
        {displayWheel && <LazyLoadImg src={wheelUrl} className={styles.logoWheel} onClick={handleWheel} />}
      </div>
    </div>
  );
};

export default HeaderLogo;
